import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import { navigate } from 'gatsby'

const NotFoundPage = props => {
  useEffect(() => {
    navigate('/')
  }, [])

  return <Chakra.Box />
}

export default NotFoundPage
